import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgForOf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurableSectionItem } from '@models/events/dto/configurable-section-item.model';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventTestimonial } from '@models/events/event-testimonial.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { getAssetFromURL } from '@models/shared/asset.model';
import { YesNoDialog } from '@modules/customer/dialogs/yes-no/yes-no.dialog';
import { EventSectionSponsorItemComponent } from '@modules/events/components/event-section-sponsor-item/event-section-sponsor-item.component';
import { EventSectionTestimonialsItemComponent } from '@modules/events/components/event-section-testimonials-item/event-section-testimonials-item.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { EventStore } from '@services/stores/event.store';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../../../directives/swiper.directive';
import { TextSizeDirective } from '../../../../directives/text-size.directive';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { UpdateActions } from '@models/shared/enums/update-actions.enum';

@Component({
  selector: 'app-event-section-testimonials',
  standalone: true,
  imports: [
    EventSectionSponsorItemComponent,
    SwiperDirective,
    EventSectionTestimonialsItemComponent,
    CdkDropList,
    CdkDrag,
    NgForOf,
    CdkDragHandle,
    TranslateModule,
    CamelCasePipe,
    TextSizeDirective,
  ],
  providers: [NotificationService],
  templateUrl: './event-section-testimonials.component.html',
  styleUrl: './event-section-testimonials.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventSectionTestimonialsComponent
  implements OnInit, AfterViewInit
{
  private _testimonials: EventTestimonial[] = [];
  get testimonials(): EventTestimonial[] | undefined {
    return this._testimonials;
  }

  @Input()
  set testimonials(value: EventTestimonial[] | undefined) {
    if (value) {
      this._testimonials = value.sort((a, b) => a.order - b.order);
    }
  }

  @Input() event?: Event;
  @Input() isEdit?: boolean = false;
  @Output() onEdit = new EventEmitter<ConfigurableSectionItem>();
  @Output() configureSection = new EventEmitter<void>();
  activeIndex: number = 0;
  draggedTestimonial?: EventTestimonial;
  selectedTestimonial?: EventTestimonial;
  swiperConfigTestimonials: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 2000,
    loop: true,
    autoplay: {
      delay: 6000,
    },
    spaceBetween: 1,
    centeredSlides: true,
    injectStyles: [
      `
        :host .swiper { overflow: hidden;}
        ${this.event?.isGeneric() ? ':host .swiper { padding-top: 70px; }' : ''}
         ${
           !this.event?.isGeneric()
             ? ':host .swiper-wrapper { justify-content: center; }'
             : ''
         }
        :host .slider-active .swiper-wrapper { justify-content: normal; }
      `,
    ],
    breakpoints: {
      768: {
        loop: false,
        autoplay: false,
        centeredSlides: false,
      },
    },
  };

  constructor(
    private eventService: EventService,
    private eventStore: EventStore,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {}

  drop(event: CdkDragDrop<any[]>, draggedTestimonial?: EventTestimonial) {
    if (this.testimonials)
      moveItemInArray(
        this.testimonials,
        event.previousIndex,
        event.currentIndex,
      );

    if (draggedTestimonial) {
      this.eventService
        .updateEvent(this.event?.id, {
          testimonial: {
            id: this.draggedTestimonial?.id,
            userName: this.draggedTestimonial?.userName,
            userJobTitle: this.draggedTestimonial?.userJobTitle,
            description: this.draggedTestimonial?.description,
            userCity: this.draggedTestimonial?.userCity,
            userAvatarAsset: this.draggedTestimonial?.userAvatarUrl
              ? getAssetFromURL(this.draggedTestimonial?.userAvatarUrl)
              : undefined,
            order: event.currentIndex,
          },
        })
        .subscribe({
          next: () => {
            if (this.event) this.eventStore.refreshEvent(this.event.uri);
          },
        });
    }
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  sortTestimonialsByOrder(testimonials: EventTestimonial[]) {
    return testimonials.sort((a, b) => a.order - b.order);
  }

  onUpdate(action: UpdateActions, testimonial: EventTestimonial) {
    if (action === UpdateActions.EDIT) {
      this.onEdit.emit({
        testimonial: testimonial,
      });
    } else if (action === UpdateActions.COPY) {
      const newItem = {
        isCopy: true,
        id: 0,
        userAvatarAsset: testimonial.userAvatarUrl
          ? getAssetFromURL(testimonial.userAvatarUrl)
          : undefined,
        userName: testimonial.userName,
        userJobTitle: testimonial.userJobTitle,
        description: testimonial.description,
      };
      const eventRequest = {
        testimonial: newItem,
      };
      this.update(eventRequest);
    } else if (action === UpdateActions.DELETE) {
      this.removeTestimonial(testimonial);
    }
  }

  removeTestimonial(testimonial: EventTestimonial) {
    const dialogRef = this.dialog.open(YesNoDialog, {
      maxWidth: '602px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      data: {
        title: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_TITLE',
        message: 'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ITEM_CONFIRMATION',
        yesLabel: 'APP.YES',
        font: this.event?.eventTemplate?.font,
        isDeleteDialog: true,
      },
      panelClass: ['normal-dialog'],
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && this.event) {
        this.eventService
          .updateEvent(this.event?.id, {
            testimonialDeleteId: testimonial.id,
          })
          .subscribe({
            next: () => {
              if (this.event?.uri) {
                this.eventStore.refreshEvent(this.event?.uri);
              }
            },
            error: () => {
              this.notificationService.error(
                this.translateService.instant(
                  'APP.ADVANCED_ONE_COLUMN_EVENT.DELETE_ERROR',
                ),
              );
            },
          });
      }
    });
  }

  selectTestimonial(testimonial: EventTestimonial) {
    if (testimonial !== this.selectedTestimonial) {
      this.selectedTestimonial = testimonial;
    } else {
      this.selectedTestimonial = undefined;
    }
  }

  update(req?: EventUpdateRequest) {
    if (this.event) {
      if (req) {
        req.localization = this.translateService.currentLang;
      }

      this.eventService.updateEvent(this.event.id, req).subscribe((event) => {
        this.event = event;
        this.eventStore.setEvent(event);
      });
    }
  }

  ngOnInit(): void {
    if (
      (this.event && this.event.type === EventType.ADVANCED_ONE_COLUMN) ||
      this.event?.isGeneric()
    ) {
      this.swiperConfigTestimonials = {
        slidesPerView: this.event?.isGeneric() ? 'auto' : 1,
        speed: 2000,
        loop: this.event?.isGeneric(),
        autoplay: {
          delay: 6000,
        },
        centeredSlides: true,
        injectStyles: [
          `
      :host .swiper { overflow: visible; }
      :host .swiper-wrapper { justify-content: left; }
      :host .slider-active .swiper-wrapper { justify-content: normal; }
    `,
        ],
        breakpoints: {
          768: {
            slidesPerView: this.event?.isGeneric() ? 2 : 'auto',
            loop: false,
            autoplay: this.event?.isGeneric(),
            centeredSlides: true,
          },
        },
      };
    }
  }
  ngAfterViewInit(): void {
    const swiperContainer: SwiperContainer | null = document.querySelector(
      '.testimonial-swiper',
    );

    if (swiperContainer) {
      const swiperEl = swiperContainer.shadowRoot?.querySelector('.swiper');
      swiperContainer.swiper.on('slideChange', () => {
        this.activeIndex = swiperContainer.swiper.realIndex;
      });
      swiperContainer.swiper.on('touchEnd', () => {
        const lastIndex = swiperContainer.swiper.activeIndex;
        if (lastIndex + 2 === this.testimonials?.length) {
          this.activeIndex = swiperContainer.swiper.activeIndex + 1;
        }
      });

      if (swiperEl) {
        swiperContainer.swiper.on('resize', (instance: any) => {
          if (this.testimonials) {
            if (
              instance.size >
              this.testimonials.length * this.getSwiperElementFullWidth()
            ) {
              swiperEl.classList.remove('slider-active');
            } else {
              swiperEl.classList.add('slider-active');
            }
          }
        });
      }
    }
  }

  getSwiperElementFullWidth(): number {
    if (this.event && this.event.type === EventType.ADVANCED_ONE_COLUMN) {
      return 405;
    }

    return 363;
  }

  onConfigureSection() {
    this.configureSection.emit();
  }

  protected readonly EventTestimonial = EventTestimonial;
  protected readonly EventType = EventType;
  protected readonly UpdateActions = UpdateActions;
}
